@font-face {
    font-family: 'GT Pressura Light';
    src: url("./GT-Pressura-Light.woff2") format('woff2'), url("./GT-Pressura-Light.woff") format("woff");
}

@font-face {
    font-family: 'TradeGothicLTPro-BdCn20';
    src: url("./TradeGothicLTPro-BdCn20.woff2") format("woff2"), url("./TradeGothicLTPro-BdCn20.woff") format("woff");
}

body{
    /*background-color: #2c2e33;*/
    color: #fff;
    font-family: 'GT Pressura Light', 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', sans-serif;
    font-size: 15px;
    line-height: 1.4;
    font-weight: 300;
    /* -webkit-font-smoothing: antialiased; */
}
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
}

a{
    cursor: pointer;
    color: inherit;
    text-decoration: none;
    border-bottom: 1px dotted;
}

.erg.container {
    margin: auto;
    min-height: 100vh;
}

.erg-wrap{
    margin: auto;
    max-width: 100vw;
    max-height: 70vh;
    border-radius: 8px;
    background: rgba(100, 100, 100, 0.1);
    padding: 48px;
}

.erg-field{
    position: relative;
    margin-bottom: 48px;
}
.erg-fields{
    display: flex;
    margin-left: -16px;
}
.erg-fields .erg-field{
    flex: 1;
    margin-left: 16px;
}
.erg-label{
    font-size: 22px;
    font-weight: 500;
    color: #7b808c;
    position: absolute;
    top: 0.25rem;
    pointer-events: none;
    padding-left: 0.125rem;
    z-index: 1;
    font-weight: normal;
    -webkit-transition: all 0.28s ease;
    transition: all 0.28s ease;
}
.erg-input{
    display: block;
    background: transparent;
    border-radius: 0;
    padding: 4px 2px;
    border: 0 none transparent;
    color: #fff;
    font-size: 30px;
    font-family: inherit;
    font-weight: 500;
    transition: .2s;
    cursor: text;
    /* font-weight: inherit; */
    -webkit-transition: all 0.28s ease;
    transition: all 0.28s ease;
    box-shadow: none;
}
.erg-input::placeholder{
    color: transparent;
}
.erg-input:focus::placeholder{
    color: #7b808c;
}

.erg-input:focus ~ .erg-label,
.erg-input.focus ~ .erg-label,
.erg-input.val ~ .erg-label,
.erg-input.complete ~ .erg-label,
.erg-input.invalid ~ .erg-label{
    font-size: 0.8rem;
    color:#7b808c;
    top: -1rem;
    left: 0;
}
.erg-bar {
    position: relative;
    border-bottom: 0.0625rem solid #999;
    display: block;
}
.erg-bar::before {
    content: '';
    height: 0.125rem;
    width: 0;
    left: 50%;
    bottom: -0.0625rem;
    position: absolute;
    background: #839b87;
    -webkit-transition: left 0.28s ease, width 0.28s ease;
    transition: left 0.28s ease, width 0.28s ease;
    z-index: 2;
}
.erg-input:focus ~ .erg-bar::before,
.erg-input.focus ~ .erg-bar::before{
    width: 100%;
    left: 0;
}
.erg-button {
    background: #ff481a;
    color: #fff;
    font-size: 30px;
    font-family: 'TradeGothicLTPro-BdCn20', sans-serif;
    border: none;
    border-radius: 4px;
    padding: 12px 20px;
    display: block;
    width: 70%;
    margin: auto;
    letter-spacing: .5px;
    transition: .2s;
    cursor: pointer;
}
.erg-button:hover,
.erg-button:focus{
    background: #ff481a;
}
.erg-button.submit {
    background-image: url(data:image/svg+xml;base64,PHN2ZyBjbGFzcz0ic3ZnLWxvYWRlciIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iNTUiIGhlaWdodD0iNTUiIHZpZXdCb3g9IjAgMCA4MCA4MCI+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTQwIDcyQzIyLjQgNzIgOCA1Ny42IDggNDBTMjIuNCA4IDQwIDhzMzIgMTQuNCAzMiAzMmMwIDEuMS0uOSAyLTIgMnMtMi0uOS0yLTJjMC0xNS40LTEyLjYtMjgtMjgtMjhTMTIgMjQuNiAxMiA0MHMxMi42IDI4IDI4IDI4YzEuMSAwIDIgLjkgMiAycy0uOSAyLTIgMnoiPjxhbmltYXRlVHJhbnNmb3JtIGF0dHJpYnV0ZVR5cGU9InhtbCIgYXR0cmlidXRlTmFtZT0idHJhbnNmb3JtIiB0eXBlPSJyb3RhdGUiIGZyb209IjAgNDAgNDAiIHRvPSIzNjAgNDAgNDAiIGR1cj0iMC42cyIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiLz48L3BhdGg+PC9zdmc+);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 20px;
    color: transparent!important;
    transition-duration: 0s;
}

.erg-wrap .token {
    color: #555;
    padding: 10px;
    text-align: center;
    font-weight: 500;
}

.erg-wrap .error {
    font-size: 22px;
    color: #e41029;
    padding: 10px;
    text-align: center;
    font-weight: 500;
}
