.App {
  /*text-align: center;*/
  /*background-image: url("./eo_w_bg.png");*/
  /*background-size: contain;*/
}

.App-logo {
  height: 5vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  /*.App-logo {*/
  /*  animation: App-logo-spin infinite 20s linear;*/
  /*}*/
}

.App-header {
  background-color: #0a0f1b;
  min-height: 5vh;
  max-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
